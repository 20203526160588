<nz-layout>
    <app-data-loader [function]="initializationFunction">
        <ng-template>
            <nz-sider class="Sider" nzTheme="light" *ngIf="isAuthenticated">
                <div class="Sider-Wrapper">
                    <div>
                        <div class="Sider-Logo"></div>

                        <ul class="Menu" nz-menu nzMode="inline">
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/clienti"
                                [nzSelected]="pathStartsWith('/clienti')"
                            >
                                Clienți
                            </li>
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/harti"
                                [nzSelected]="pathStartsWith('/harti') && !pathIncludes('clienti')"
                            >
                                Hărți
                            </li>
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/terenuri"
                                [nzSelected]="pathStartsWith('/terenuri')"
                            >
                                Terenuri
                            </li>
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/documente"
                                [nzSelected]="pathStartsWith('/documente')"
                            >
                                Documente poliță
                            </li>
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/sarcini"
                                [nzSelected]="pathStartsWith('/sarcini')"
                            >
                                Sarcini
                            </li>
                            <li
                                *ngIf="canAccessOverview()"
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/harti/clienti"
                                [nzSelected]="pathStartsWith('/harti/clienti')"
                            >
                                Hartă clienți
                            </li>
                            <li
                                class="Unselectable"
                                nz-menu-item
                                routerLink="/rapoarte"
                                [nzSelected]="pathStartsWith('/rapoarte')"
                            >
                                Rapoarte vegetație
                            </li>
                        </ul>
                    </div>

                    <div class="Sider-Content">
                        <clients-switch></clients-switch>
                    </div>

                    <div class="Sider-Footer">
                        <small class="Version-Code">v{{ versionCode }}</small>
                    </div>
                </div>
            </nz-sider>
            <nz-layout class="Layout" [style.marginLeft]="isAuthenticated ? '200px' : '0'">
                <nz-content class="Content">
                    <router-outlet></router-outlet>
                </nz-content>
            </nz-layout>
        </ng-template>
    </app-data-loader>
</nz-layout>
