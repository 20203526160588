import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { LayersService } from '@core/services/layers.service';
import { PermissionsService } from '@core/services/permissions.service';
import { environment } from '@environments/environment';
import { AuthService } from '@modules/auth/services/auth.service';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Client } from '@shared/models/client';
import { ConfigService } from '@shared/services/config.service';
import _ from 'lodash';
import { NzI18nService, ro_RO } from 'ng-zorro-antd/i18n';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    currentPath = '';
    initializationFunction = this.layersService.getInitializationData;

    isAuthenticated = false;
    versionCode: string = null;
    isProduction = environment.production;

    private currentClient: Client = null;

    destroyRef = inject(DestroyRef);

    constructor(
        private authService: AuthService,
        private router: Router,
        private internationalization: NzI18nService,
        private layersService: LayersService,
        private clientsService: ClientsService,
        private configService: ConfigService,
        private permissionsService: PermissionsService,
    ) {
        this.authService.isAuthenticated.subscribe(
            (isAuthenticated) => (this.isAuthenticated = isAuthenticated),
        );
        this.versionCode = this.configService.configuration.version;
    }

    ngOnInit(): void {
        this.internationalization.setLocale(ro_RO);

        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((navEvent) => {
            if (navEvent instanceof NavigationEnd) {
                this.currentPath = navEvent.url;
            }
        });

        this.clientsService.CurrentClient.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (client) => {
                if (this.currentClient) {
                    // If a switch of type client -> client occurs (not null -> client), the client-dependent data must be reset
                    this.layersService.eraseNdviData();

                    // If the user was viewing a Field when the client changed, we route him back
                    if (
                        this.currentPath.includes('terenuri') &&
                        _(this.currentPath).split('/').size() > 2
                    ) {
                        this.router.navigateByUrl('/terenuri');
                    }
                }

                this.currentClient = client;
            },
        );
    }

    /**
     * Checks if the current path starts with the given string
     * @param value The value to compare to
     */
    pathStartsWith = (value: string): boolean => {
        return this.currentPath.startsWith(value);
    };

    /**
     * Checks if the current path includes the given string
     * @param value The value to compare to
     */
    pathIncludes = (value: string): boolean => {
        return this.currentPath.includes(value);
    };

    canAccessOverview = (): boolean => {
        return this.permissionsService.Permissions.includes('core.view_overview_map');
    };
}
