import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataLoaderComponent } from './components/data-loader/data-loader.component';
import { AuthModule } from '@modules/auth/auth.module';
import { registerLocaleData } from '@angular/common';
import { IconsProviderModule } from './icons-provider.module';
import { NZ_I18N, ro_RO, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeRo from '@angular/common/locales/ro';
import { ClientsModule } from '@modules/clients/clients.module';
import { ConfigService } from '@shared/services/config.service';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { SharedModule } from '@shared/shared.module';

registerLocaleData(localeRo);

@NgModule({
    declarations: [AppComponent, DataLoaderComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        IconsProviderModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ClientsModule,
        NzLayoutModule,
        NzMenuModule,
        SharedModule,
    ],
    providers: [
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: (cf: ConfigService) => () => cf.loadConfig(),
            deps: [ConfigService],
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'ro' },
        { provide: NZ_I18N, useValue: ro_RO },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1,
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
