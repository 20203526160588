import jwtDecode from 'jwt-decode';
import { Component, OnInit, ContentChild, TemplateRef, Input } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { AuthService } from '@modules/auth/services/auth.service';
import { switchMap } from 'rxjs/operators';
import { StorageService } from '@shared/services/storage.service';
import { differenceInMinutes } from 'date-fns';
import { PermissionsService } from '@core/services/permissions.service';
import { TasksService } from '@modules/tasks/services/tasks.service';

@Component({
    selector: 'app-data-loader',
    templateUrl: './data-loader.component.html',
    styleUrls: ['./data-loader.component.less'],
})
export class DataLoaderComponent implements OnInit {
    /**
     * Function that returns a chain of Observables which should be called in parallel
     * by the component and act upon their completion
     */
    @Input()
    function: () => Observable<any>;

    /**
     * True when the component has initialized the required functions
     * and it's waiting for a response
     */
    isLoading = false;

    /**
     * True when the component can display its child content
     */
    isContentVisible = false;

    loadingScreenStyle = {
        position: 'relative',
        height: '100vh',
        width: '100vw',
    };

    private isLoggingEnabled = false; // !environment.production;

    @ContentChild(TemplateRef) template: TemplateRef<any>;

    constructor(
        private storage: StorageService,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private tasksService: TasksService,
    ) {}

    ngOnInit() {
        this.authService.isAuthenticated.subscribe((isAuthenticated) => {
            if (this.isLoggingEnabled)
                console.warn('[DataLoader] isAuthenticated', isAuthenticated);
            this.isContentVisible = false;
            let minutesSinceIssued = 0;

            const jwtToken: string = this.storage.getJwtToken();

            if (jwtToken) {
                const decodedToken = jwtDecode(jwtToken);
                const issuedDate: Date = new Date(decodedToken.orig_iat * 1000);
                if (this.isLoggingEnabled)
                    console.warn('[DataLoader] token issuedDate', issuedDate);
                minutesSinceIssued = differenceInMinutes(new Date(), issuedDate);
            }

            /**
             * The user is authenticated and the required function hasn't been called yet
             */
            if (isAuthenticated && !this.isLoading) {
                if (this.isLoggingEnabled)
                    console.warn('[DataLoader] Calling initialization functions');

                this.isLoading = true;
                const requestsArray: any[] = [
                    this.permissionsService.getPermissions(),
                    this.tasksService.getUsers(),
                    this.authService.getUserProfile(),
                ];

                /**
                 * Only refresh the token if 15 minutes have passed since the issue date
                 * This is used to restrict the refreshing of the token immediately after login
                 */
                if (minutesSinceIssued >= 15) {
                    if (this.isLoggingEnabled) console.warn('[DataLoader] Refreshing token');
                    requestsArray.push(this.authService.refresh());
                }

                forkJoin(requestsArray)
                    .pipe(
                        switchMap(() => {
                            return this.function();
                        }),
                    )
                    .subscribe(
                        () => {
                            this.isContentVisible = true;
                            this.isLoading = false;
                        },
                        (error) => {
                            console.error('[DataLoader] initialization function error', error);
                            this.isContentVisible = true;
                            this.isLoading = false;
                            this.authService.logout();
                        },
                    );
            } else if (!this.isLoading) {
                if (this.isLoggingEnabled) console.warn('[DataLoader] Bypassing...');
                this.isContentVisible = true;
            }
        });
    }
}
